.socialContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  margin-left: 49px;
}

@media (max-width: 1250px) {
  .socialContainer {
    flex-direction: row;
    width: 300px;
    margin: 20px auto;
  }

  .socialContainer > a {
    transform: scale(2);
  }
}

@media (max-width: 768px) {
  .socialContainer {
    flex-direction: row;
    width: 300px;
    margin: auto;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%);
    transition: all 0.3s ease-in-out;
  }
  .socialContainer > a {
    transition: all 0.3s ease-in-out;
    transform: scale(1.5);
  }
}

@media (max-width: 550px) {
  .socialContainer {
    width: 250px;
    margin-top: 20px;
    gap: 4px;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%);
  }
}
