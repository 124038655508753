.formContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formInput::placeholder {
  color: var(--color-white);
}

.formInput:focus {
  outline: 2px solid #2f2f2f;
}

.formInput {
  background: #181818;
  border-radius: 19px;
  border: none;
  color: var(--color-white);
  border-radius: 19px;
  width: 100%;
  height: 71px;
  padding-left: 20px;
}

.formContainer > div > button {
  width: 100%;
}

@media (max-width: 1250px) {
}

@media (max-width: 768px) {
}

@media (max-width: 550px) {
}
