.subtitle {
  margin-top: 14px;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  height: 55px;
  width: 352px;
  margin-bottom: 42px;
  color: var(--color-white);
  z-index: 10;
}

@media (max-width: 1250px) {
  .subtitle {
    width: auto;
  }
}

@media (max-width: 768px) {
  .subtitle {
    width: auto;
  }
}

@media (max-width: 550px) {
  .subtitle {
    width: 230px;
  }
}
