.cardContainer {
  height: 600px;
  min-width: 400px;
  max-width: 400px;
  border-radius: 40px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardHeader {
  height: 55%;
  width: 100%;
  padding-top: 7px;
  position: relative;
}

.cardHeader > img {
  object-fit: cover;
  height: 100%;
  width: 95%;
  border-radius: 40px;
}
.imageText {
  position: absolute;
  color: white;
  bottom: 5%;
  left: 10%;
  font-size: 30px;
  font-weight: 600;
}
.cardBody {
  height: 30%;
  width: 100%;
  position: relative;
  text-align: start;
}

.cardBody > h5 {
  padding: 0 10px;
  font-size: 20px;
  color: #e5a000;
  font-weight: 300;
  margin: 10px 0 5px 0;
  height: 46px;
}
.cardBody > p {
  font-size: 16px;

  width: 100%;
  line-height: 30px;
  text-align: start;
  padding: 0 10px;

  overflow: hidden;
  max-height: 115px;
}

.cardBody > div {
  font-size: 16px;
  line-height: 28px;
  background: #ffffff;
  padding-left: 0;
}
.cardBodyFullBio {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  gap: 10px;
}

.cardBodyFullBio > h5 {
  padding: 0 0px;
  font-size: 20px;
  color: #e5a000;
  font-weight: 300;
  margin: 10px 0 5px 0;
  height: 46px;
  text-align: start;
}

.cardBodyFullBio > h3 {
}

.cardBodyFullBio > p {
  height: 100%;
  width: 100%;
  min-height: auto;
  text-align: start !important;
}

.cardBodyFullBio > label {
  margin: 5px 0 20px 0;
}

.toggleText {
  padding-left: 4px;
  font-size: 16px;
  line-height: 30px;
  color: #4670dc;
  cursor: pointer;
}

.cardBodyFullBio > label {
  font-size: 18px;
  margin: 50px;
}

.cardFooter {
  height: 63px;
  width: 95%;
  background: #f7f7f7;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftSlot {
  width: 50%;
  height: 100%;
  display: grid;
  place-content: center;
}

.rightSlot {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.rightSlot > a {
  cursor: pointer;
}

.rightSlot > a > svg {
  width: auto;
  height: 15px;
  /* filter: invert(20%) sepia(100%) saturate(4000%); */
  filter: invert(88%) sepia(35%) saturate(335%)
    hue-rotate(155deg) brightness(97%) contrast(93%);
}

.rightSlot > a:nth-child(3) > svg {
  filter: none !important;
  height: 19px;
  margin-bottom: 1px;
}

@media (max-width: 1250px) {
  .cardBodyFullBioText {
  }
}

@media (max-width: 768px) {
  .cardBodyFullBioText {
    font-size: 14px;
    line-height: 20px;
  }
  .cardBodyFullBio > label {
    margin: 20px 0;
  }
  .cardBodyFullBio > h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .cardBodyFullBio > h5 {
    font-size: 20px;
    line-height: 25px;
  }
  .cardBodyFullBioText {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 550px) {
  .cardContainer {
    height: 500px;
    min-width: 275px;
    max-width: 275px;
  }

  .imageText {
    bottom: 5%;
    left: 10%;
    font-size: 20px;
    font-weight: 600;
  }
  .cardBody {
    height: 42%;
  }

  .cardBody > h5 {
    padding: 0 10px;
    font-size: 16px;
    color: #e5a000;
    font-weight: 300;
    margin: 10px 0 5px 0;
    height: 36px;
    line-height: 19px;
  }
  .cardBody > p {
    font-size: 14px;
    line-height: 20px;
    text-align: start;
    max-height: 145px;
  }

  .cardFooter {
    height: 40px;
  }
  .leftSlot {
    font-size: 20px;
  }

  .cardHeader {
    height: 45%;
  }
  .cardBodyFullBio > h3 {
    font-size: 20px;
    line-height: 20px;
  }

  .cardBodyFullBio > h5 {
    margin: 0;
    font-size: 15px;
    line-height: 15px;
  }

  .cardBodyFullBio > label {
    margin: 0;
  }

  .cardBodyFullBioText {
    font-size: 12px;
    line-height: 20px;
  }
}
