.container {
  height: 30px;
  display: flex;
  align-items: center;
}

.hamburger .line {
  width: 30px;
  height: 3px;

  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger {
  background: var(--color-white);
}

.line {
  background: var(--color-black);
}

.hamburger:hover {
  cursor: pointer;
}

/* ONE */

.hamburger.isActive .line:nth-child(2) {
  opacity: 0;
}

.hamburger.isActive .line:nth-child(1) {
  -webkit-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

.hamburger.isActive .line:nth-child(3) {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  -o-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.sidemenu {
  height: calc(100vh);
  width: 100%;
  position: fixed;
  top: 76px;
  left: 100vw;
  transition: all ease-i n-out 0.3s;
  background: var(--color-black);
  z-index: 1000;
  bottom: 0;
  overflow: hidden;
}

.isVisible {
  transition: all ease-in-out 0.3s;
  transform: translateX(-100%);
}

.navContainer {
  padding-top: 70px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--color-white);
}

.navItem {
  white-space: nowrap;

  font-size: 26px;
  width: fit-content;
  text-transform: uppercase;
  width: 50%;
  text-align: center;
  padding: 15px 0 15px 0;
}
