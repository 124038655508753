.backgroundImage {
  background-image: url(/public/backgrounds/ourClientsPage.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.welcomeContainer {
  width: 100%;
  height: auto;
  height: auto;
  position: relative;
}

.imageContainer {
  width: 100%;
  height: auto;
}

.homeWelcomeImage {
  width: inherit;
  object-fit: contain;
  width: -webkit-fill-available;
  max-height: 52vh;
}

.welcomeContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.sectionTextContainer > h2 {
  font-size: 70px;
  line-height: 75px;
  color: white;
  letter-spacing: -0.065em;
  text-align: center;
  font-weight: 500;
  white-space: break-spaces;
}

.sectionTextContainer > h2 > strong {
  color: orange;
  font-size: 100px;
}

.sectionsGap {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.backgroundImage {
  background-image: url(/public/ourClientsBackground.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1250px) {
  .sectionTextContainer > h2 {
    font-size: 48px;
    line-height: 58px;
    font-weight: 500;
  }
  .sectionsGap {
    gap: 100px;
  }
  .sectionTextContainer > h2 > strong {
    font-size: 83px;
  }
}

@media (max-width: 768px) {
  .sectionTextContainer > h2 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
  }
  .sectionsGap {
    gap: 75px;
  }
  .sectionTextContainer > h2 > strong {
    font-size: 65px;
  }
}

@media (max-width: 550px) {
  .sectionTextContainer > h2 {
    font-size: 30px;
    line-height: 29px;
    font-weight: 500;
  }
  .sectionTextContainer > h2 > strong {
    font-size: 55px;
  }
}
