.sectionContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 100px 15px;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}
