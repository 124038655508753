.sectionContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.titleContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 54px;
}

.titleContainer > h1 {
  text-align: center;
  max-width: 1100px;
  font-size: 90px;
  line-height: 84px;
  letter-spacing: -0.02em;
  text-align: center;
}

.sectionContent {
  display: flex;
  height: 408px;
  justify-content: space-between;
  gap: 100px;
}

.cardContainer {
  max-width: 862px;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 650px;
}

.b2bCard {
  background: white;
  height: 408px;
  max-width: 862px;
  border-radius: 26px;
  display: flex;
  flex-direction: row;
  flex: auto;
}

.cardFigureSlot {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContentSlot {
  width: 55%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 45px;
}

.cardContentSlot > h3 {
  font-size: 30px;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 500;
}

.cardContentSlot > p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.socialWrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.socialContainer {
  max-width: 300px;
  height: 250px;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 40px;
}

.socialContainer > p {
  max-width: 246px;
  color: var(--color-white);
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.055em;
  text-align: left;
}

.cardButtonContainer > button {
  background: #0c365a;
  height: 50.87315368652344px;
  width: 142.2371826171875px;

  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.socialButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.socialButtonContainer > button {
  height: 70.59947967529297px;
  width: 172.3457794189453px;

  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  background: #fafafa;
  box-shadow: 5px 5px 32px -36px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
}

.iconContainer {
  height: 40px;
  width: 40px;
  background-size: cover;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  min-width: 130px;
}

@media (max-width: 1250px) {
  .cardFigureSlot {
    width: 100%;
    height: auto;
  }

  .cardFigureSlot > img {
    width: 100%;
    height: auto;
  }
  .sectionContent {
    height: 100%;
    gap: 50px;
    flex-direction: column;
    align-items: center;
  }

  .b2bCard {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .cardContainer {
    max-width: 600px;
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 280px;
  }
  .b2bCard {
    background: white;
    height: auto;
    max-width: none;
    width: 100%;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    flex: auto;
    padding: 30px 15px;
  }
  .socialContainer {
    gap: 50px;
  }

  .socialContainer > p {
    max-width: 100%;
    text-align: start;
  }

  .cardContentSlot {
    width: 100%;
    padding: 0;
    gap: 50px;
  }
  .titleContainer > h1 {
    font-size: 70px !important;
    line-height: 70px !important;
  }
}

@media (max-width: 768px) {
  .sectionContainer {
    flex-direction: column;
  }
  .socialContainer {
    width: 100%;
  }

  .socialButtonContainer {
    flex-direction: row;
  }

  .buttonContent {
    min-width: 110px;
  }

  .titleContainer > h1 {
    font-size: 45px !important;
    line-height: 55px !important;
  }
}

@media (max-width: 550px) {
}
