.sectionContainer {
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rightSlot {
  max-width: 50%;
  width: auto;
  display: flex;
  height: 100%;
  flex: auto;
  align-items: center;
}

.rightSlotContainer {
  height: 100%;
  position: relative;
}

.rightSlotImage {
  max-height: 486px;
  object-fit: contain;
  width: 100%;
}

.leftSlot {
  width: auto;
  max-width: 50%;
  flex: auto;
  display: flex;
  height: 100%;
}

.leftSlotContent {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftSlotHeader {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.leftSlotHeader > h4 {
  color: var(--color-white);
  font-size: 104px;
  line-height: 90px;
  letter-spacing: 0.005em;
  font-weight: 500;
  height: auto;
}
.leftSlotBody {
  width: 100%;
  height: auto;
  flex: auto;
  display: flex;
  color: var(--color-white);
  text-align: start;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.01em;
  padding-top: 70px;
}
.leftSlotBody > p {
  max-width: 500px;
}
.leftSlotFooter {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
}
.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  flex: row;
  gap: 14px;
  width: 100%;
}

@media (max-width: 1250px) {
  .sectionContainer {
    flex-direction: column-reverse;
    height: auto;
    width: 100%;
    align-items: center;
    gap: 100px;
  }

  .rightSlot {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rightSlotImage {
    width: 80%;
  }

  .leftSlotBody > p {
    max-width: 100%;
  }

  .rightSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }

  .leftSlot {
    width: 100%;
    max-width: 100%;
  }

  .leftSlotHeader {
    width: 100%;
  }

  .leftSlotHeader > h4 {
    font-size: 75px;
    line-height: 65px;
  }

  .leftSlotBody {
    padding-top: 15px;
  }

  .leftSlotFooter {
    padding-top: 50px;
  }
}

@media (max-width: 768px) {
  .rightSlot {
    padding-top: 100px;
  }

  .leftSlotHeader > h4 {
    font-size: 50px;
    line-height: 60px;
  }
  .leftSlotBody > p {
    font-size: 16px;
    line-height: 23px;
  }
  .rightSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }
  .sectionsGap {
    gap: 50px;
  }
}

@media (max-width: 550px) {
  .leftSlotHeader > h4 {
    font-size: 35px;
    line-height: 43px;
  }

  .rightSlot {
    padding-top: 0px;
  }
}
