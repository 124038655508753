.sectionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  gap: 30px;
}

.rightSlotContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.rightSlotContainer > h3 {
  color: var(--color-orange);
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.005em;
  text-align: right;
}

.rightSlotContainer > div > p {
  color: var(--color-white);
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: right;
}

.leftSlotContainer {
  width: 50%;
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
}

.leftSlotContainer > img {
  width: 100%;
  max-height: 578px;
  object-fit: contain;
}

.childrenContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 1250px) {
  .sectionContainer {
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
  }

  .rightSlotContainer {
    width: 100%;
    gap: 50px;
  }

  .leftSlotContainer {
    width: 80%;
  }
  .rightSlotContainer div > p {
    font-size: 20px;
    line-height: 36px;
  }
}

@media (max-width: 768px) {
  .rightSlotContainer {
    gap: 30px;
  }
  .rightSlotContainer > h3 {
    font-size: 22px;
    line-height: 32px;
  }
  .rightSlotContainer div > p {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (max-width: 550px) {
  .rightSlotContainer div > p {
    font-size: 14px;
    line-height: 20px;
  }
}
