.homePageBackground {
  background-image: url(/public/backgrounds/homePage.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.sectionsGap {
  gap: 150px;
  display: flex;
  flex-direction: column;
}

.welcomeContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.imageContainer {
  width: 100%;
  height: auto;
}

.homeWelcomeImage {
  width: inherit;
  object-fit: cover;
  min-height: 100vh;
}

.welcomeContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.welcomeContent > h3 {
  color: white;
  max-width: 470px;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 500;
}

.welcomeContent > h1 {
  line-height: 105px;
  text-align: center;
  background: linear-gradient(
    100.46deg,
    #d6b717 9.9%,
    rgba(155, 143, 65, 0.958854) 66.39%,
    rgba(62, 66, 51, 0.9) 95.58%
  );
  max-width: 691px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 130px;
  line-height: 126px;
  letter-spacing: -0.055em;
  text-align: center;
}

@media (max-width: 1250px) {
  .welcomeContent > h3 {
    font-size: 35px;
    line-height: 30px;
  }

  .welcomeContent > h1 {
    max-width: 413px;
    font-size: 90px;
    line-height: 93px;
    letter-spacing: -0.055em;
  }
}

@media (max-width: 768px) {
  .welcomeContent > h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .welcomeContent > h1 {
    max-width: 300px;
    font-size: 70px;
    line-height: 73px !important;
    letter-spacing: -0.055em;
  }

  .leftSlot {
    padding-top: 100px;
  }
  .sectionsGap {
    gap: 50px;
  }
}

@media (max-width: 550px) {
  .welcomeContent > h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .sectionsGap {
    gap: 100px;
    margin-bottom: 50px;
  }
  .welcomeContent > h1 {
    max-width: 229px;
    font-size: 50px !important;
    line-height: 53px !important;
    letter-spacing: -0.055em;
  }
}
