.sectionContainer {
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.rightSlot {
  max-width: 50%;
  width: auto;
  display: flex;
  height: 100%;
  flex: auto;
  align-items: center;
}

.rightSlotImage {
  width: 100%;
  max-height: 620px;
}

.leftSlot {
  width: auto;
  max-width: 50%;
  flex: auto;
  display: flex;
  align-items: center;
}

.leftSlotContainer {
  display: flex;
  justify-content: flex-end;
}

.leftSlotContent {
  gap: 30px;
  width: 70%;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftSlotHeader {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.leftSlotHeader > h4 {
  color: var(--color-orange);
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.005em;
  font-weight: 500;
}
.leftSlotBody {
  width: 80%;
  height: auto;
  flex: auto;
  display: flex;
}
.leftSlotBody > p {
  color: var(--color-white);
  background: transparent;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.leftSlotFooter {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
}
.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  flex: row;
  gap: 14px;
  width: 100%;
}

@media (max-width: 1250px) {
  .sectionContainer {
    flex: auto;
    flex-direction: column;
    height: auto;
    gap: 100px;
  }

  .rightSlot {
    max-width: 100%;
  }
  .leftSlot {
    max-width: 100%;
  }
  .rightSlotContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightSlotContent {
    width: 100%;
  }

  .rightSlotImage {
    transition: all 0.3s ease-in-out;
    position: relative;
    width: 70%;
  }
  .leftSlotContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .leftSlotHeader > h4 {
    font-size: 27px;
  }
  .leftSlotBody > p {
    font-size: 25px;
    line-height: 35px;
    font-weight: 400;
  }
  .leftSlotBody {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .sectionContainer {
  }

  .rightSlot {
    width: 100%;
  }

  .leftSlotContent {
    width: 100%;
  }

  .leftSlotHeader > h4 {
    font-size: 20px;
  }
  .leftSlotBody > p {
    font-size: 20px;
    line-height: 31px;
  }

  .rightSlotImage {
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 550px) {
  .sectionContainer {
    gap: 80px;
  }
  .leftSlotHeader > h4 {
    font-size: 18px;
  }
  .leftSlotBody > p {
    font-size: 16px;
    line-height: 27px;
  }
}
