.headerContainer {
  width: 100%;
  height: 76px;
  background: var(--color-white);
  display: flex;
  align-items: center;
  padding: 5px 79px 0 73px;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.logoContainer {
  width: auto;
  transform: translate(5px 0);
  transition: transform 0.3s ease-in-out;
}

.navbarContainer {
  height: 17px;
  gap: 77px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.navItem {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0em;
  transition: all 0.3s ease-in-out;
}

.navItem:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .headerContainer {
    padding: 0 40px 0 10px;
  }
  .logoContainer {
    transform: scale(0.9);
  }
  .navbarContainer {
    opacity: 0;
  }
  .mobileNavIcon {
    height: 25px;
    cursor: pointer;
  }
}

@media (max-width: 1250px) {
  .navbarContainer {
    gap: 25px;
  }
  .headerContainer {
    padding: 0 45px 0 25px;
  }
}
