.sectionContainer {
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftSlot {
  max-width: 50%;
  width: auto;
  display: flex;
  height: 100%;
  flex: auto;
}

.leftSlotContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSlotImage {
  max-height: 584px;
  width: 100%;
  object-fit: contain;
}

.rightSlot {
  width: auto;
  max-width: 50%;
  flex: auto;
  display: flex;
}

.rightSlotContent {
  gap: 30px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightSlotHeader {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.rightSlotHeader > h4 {
  color: var(--color-orange);
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.005em;
  font-weight: 500;
}
.rightSlotBody {
  width: 100%;
  height: auto;
  flex: auto;
  display: flex;
  color: var(--color-white);
  text-align: end;
  background: transparent;
  font-size: 55px;
  line-height: 66px;
  letter-spacing: -0.01em;
}
.rightSlotBody > p {
  width: 100%;
}
.rightSlotFooter {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.buttonsContainer {
  display: flex;
  flex: row;
  justify-content: flex-end;
  gap: 28px;
  width: 100%;
}

@media (max-width: 1250px) {
  .sectionContainer {
    flex-direction: column-reverse;
    height: auto;
    gap: 100px;
    max-height: none;
  }

  .leftSlot {
    max-width: 100%;
  }
  .rightSlot {
    max-width: 100%;
  }
  .leftSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }

  .rightSlotContent {
    height: min-content;
  }
  .welcomeContent > h1 {
    max-width: 800px;
  }
  .rightSlotHeader > h4 {
    font-size: 27px;
  }
  .rightSlotBody > p {
    font-size: 45px;
    line-height: 56px;
  }
  .rightSlotFooter {
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .leftSlot {
  }

  .welcomeContent > h1 {
    max-width: 400px;
  }
  .rightSlotHeader > h4 {
    font-size: 20px;
  }
  .rightSlotBody > p {
    font-size: 30px;
    line-height: 41px;
  }
  .leftSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }
}

@media (max-width: 550px) {
  .welcomeContent > h1 {
    max-width: 200px !important;
  }
  .rightSlotHeader > h4 {
    font-size: 18px;
  }
  .rightSlotBody > p {
    font-size: 20px;
    line-height: 25px;
  }
  .leftSlot {
    padding-top: 0px;
  }
}
