.sectionContainer {
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: row;
}

.rightSlot {
  max-width: 50%;
  width: auto;
  display: flex;
  height: 100%;
  flex: auto;
  align-items: center;
  justify-content: center;
}

.rightSlotImage {
  width: 100%;
  max-height: 584px;
  object-fit: contain;
}

.leftSlot {
  width: auto;
  max-width: 50%;
  flex: auto;
  display: flex;
  align-items: center;
}

.leftSlotContent {
  gap: 30px;
  width: 100%;
  height: min-content;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.leftSlotHeader {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.leftSlotHeader > h4 {
  color: var(--color-orange);
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.005em;
  font-weight: 500;
}
.leftSlotBody {
  width: 100%;
  height: auto;
  flex: auto;
  display: flex;
  color: var(--color-white);
  text-align: start;
  background: transparent;
  font-size: 55px;
  line-height: 66px;
  letter-spacing: -0.01em;
}
.leftSlotBody > p {
  width: 100%;
}
.leftSlotFooter {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}
.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  flex: row;
  gap: 14px;
  width: 100%;
}

@media (max-width: 1250px) {
  .sectionContainer {
    flex: auto;
    flex-direction: column;
    height: auto;
    gap: 100px;
    min-height: 100vh;
  }

  .rightSlot {
    max-width: 100%;
  }
  .leftSlot {
    max-width: 100%;
  }
  .rightSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }

  .leftSlotContent {
    height: min-content;
  }

  .leftSlotHeader > h4 {
    font-size: 27px;
  }
  .leftSlotBody > p {
    font-size: 45px;
    line-height: 56px;
  }
  .leftSlotFooter {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .rightSlot {
  }

  .leftSlotHeader > h4 {
    font-size: 20px;
  }
  .leftSlotBody > p {
    font-size: 30px;
    line-height: 41px;
  }
  .rightSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }
}

@media (max-width: 550px) {
  .leftSlotHeader > h4 {
    font-size: 18px;
  }
  .leftSlotBody > p {
    font-size: 20px;
    line-height: 25px;
  }
}
