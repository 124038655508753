.sectionContainer {
  display: flex;
  flex-direction: row;
  gap: 50px;
  height: auto;
}

.leftSlot {
  width: 50%;
  height: 100%;
}

.leftSlot > h1 {
  font-size: 90px;
}

.rightSlot {
  width: 50%;
  height: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.formContainer > div {
  width: 100%;
}
@media (max-width: 1250px) {
  .sectionContainer {
    flex-direction: column;
    align-items: center;
  }
  .rightSlot {
    width: 80%;
  }
  .leftSlot {
    width: 80%;
    height: min-content;
  }
  .leftSlot > h1 {
    text-align: center;
    font-size: 60px !important;
    line-height: 60px !important;
  }
}

@media (max-width: 768px) {
  .leftSlot > h1 {
    font-size: 50px !important;
    line-height: 55px !important;
  }
}

@media (max-width: 550px) {
  .rightSlot {
    width: 90%;
  }
  .leftSlot {
    width: 90%;
  }
  .leftSlot > h1 {
    font-size: 45px !important;
    line-height: 45px !important;
  }
}
