.defaultButton {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px 22px;
  gap: 10px;
  width: max-content;
  height: 55px;
  background: #fafafa;
  backdrop-filter: blur(30px);
  border-radius: 10px;
  height: 55px;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-blue);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button {
  cursor: pointer;
}

.outlinedButton {
  color: var(--color-white);
  background: #00263a;
  border: 1px solid var(--color-white);
  backdrop-filter: blur(30px);
  padding: 10px 22px;
  border-radius: 10px;
  height: 55px;
  font-size: 16px;
  line-height: 25px;
  transition: all 0.2s ease-in-out;
}

.outlinedButton:hover {
  background: #3e3404;
  color: var(--color-white);
}

button:disabled {
  opacity: 0.5;
}

.defaultButton:hover {
  opacity: 0.6;
}

.socialButton {
  width: 206px;
  height: 68px;
  font-size: 20px;
  line-height: 30px;
  font-feature-settings: 'kern' off;
  background: var(--color-white);
  color: var(--color-blue);
  box-shadow: 5px 5px 32px -36px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.socialButton:hover {
  opacity: 0.9;
}

.socialButton > svg {
  color: var(--color-blue);
}

@media (max-width: 768px) {
  .socialButton {
    width: 190px;
    height: 50px;
  }
  .outlinedButton {
    height: 44px;
    font-size: 12px;
  }

  .defaultButton {
    height: 44px;
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  .socialButton {
    width: 200px;
    height: 50px;
    border-radius: 8px;
    font-size: 16px;
  }
  .socialButton > svg {
    transform: scale(1.3);
  }

  .outlinedButton {
    height: 44px;
    font-size: 12px;
    padding: 7px 11px;
  }
  .defaultButton {
    height: 44px;
    font-size: 12px;
    padding: 7px 11px;
  }
}
