.sectionContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}

.leftSlot {
  max-width: 50%;
  width: auto;
  display: flex;
  height: 100%;
  flex: auto;
}

.leftSlotContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSlotImage {
  max-height: 100%;
}

.rightSlot {
  width: auto;
  max-width: 50%;
  flex: auto;
  display: flex;
}

.rightSlotContainer {
  display: flex;
  justify-content: flex-start;
}

.rightSlotContent {
  gap: 30px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightSlotHeader {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.rightSlotHeader > h4 {
  color: var(--color-orange);
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.005em;
  font-weight: 500;
}
.rightSlotBody {
  width: 70%;
  height: auto;
  flex: auto;
  display: flex;
}
.rightSlotBody > p {
  color: var(--color-white);
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.rightSlotFooter {
  width: 100%;

  display: flex;
}
.buttonsContainer {
  display: flex;
  flex: row;
  justify-content: flex-start;
  gap: 28px;
  width: 100%;
}

@media (max-width: 1250px) {
  .sectionContainer {
    flex-direction: column-reverse;
    height: auto;
    gap: 100px;
    max-height: none;
  }

  .leftSlot {
    max-width: 100%;
  }
  .rightSlot {
    max-width: 100%;
  }
  .leftSlotImage {
    transition: all 0.3s ease-in-out;

    width: 70%;
  }
  .rightSlotContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rightSlotContent {
    gap: 30px;
    width: 70%;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .welcomeContent > h1 {
    max-width: 800px;
  }
  .rightSlotHeader {
    justify-content: flex-start;
  }

  .rightSlotBody {
    width: 100%;
  }
  .rightSlotHeader > h4 {
    font-size: 27px;
  }
  .rightSlotBody > p {
    font-size: 25px;
    line-height: 35px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .sectionContainer {
  }
  .rightSlotContent {
    width: 100%;
  }

  .rightSlotHeader > h4 {
    font-size: 20px;
  }
  .rightSlotBody > p {
    font-size: 20px;
    line-height: 31px;
  }
  .leftSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }
}

@media (max-width: 550px) {
  .sectionContainer {
    gap: 80px;
  }
  .rightSlotContent {
    gap: 30px;
    width: 100%;
  }

  .rightSlotHeader > h4 {
    font-size: 18px;
  }
  .rightSlotBody > p {
    font-size: 16px;
    line-height: 27px;
  }
  .leftSlot {
    padding-top: 0px;
  }
}
