.dot {
  color: var(--color-primary);
}

h1 {
  color: var(--color-white);
}

.smallTitle {
  font-size: 40px;
}

.large {
  font-size: 150px;
}
