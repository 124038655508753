.container {
  height: 500px;
  width: 100%;
}

.wrapper > div > div {
  right: 543px;
  gap: 15px;
}

.nextButton {
  display: flex;
  color: red;
  background: red;
  height: 50px;
  width: 20px;
  position: absolute;
  right: 50px;
  top: 20px;
}

.slideContent {
  width: auto;
  text-align: center;
  font-size: 18px;
  height: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow-x: visible;
}

.container > .swiperPagination {
  position: absolute;
  top: 97%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 30vw;
}

.container > .swiperPagination > span {
  background: var(--color-orange) !important;
}

.container
  div
  > div:nth-child(8)
  > article
  > div:first-child
  > img {
  max-height: 100%;
}

@media (max-width: 1250px) {
  .wrapper > div > div {
    right: 219px;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .container {
    height: 590px;
  }
  .imageWrapper {
    width: 250px;
    height: 250px;
  }

  .itemContainer {
    width: 250px;
    height: 250px;
  }

  .wrapper > div > div {
    right: -40px;
    gap: 0px;
  }
}

@media (max-width: 550px) {
  .container {
    width: 490px;
    height: 530px;
  }
  .imageWrapper {
    width: 150px;
    height: 150px;
  }

  .itemContainer {
    width: 150px;
    height: 150px;
  }
  .dividerContainer {
    gap: 30px;
  }
  .wrapper > div > div {
    position: relative;
  }
  .wrapper > div > div {
    right: -10px;
    gap: 0px;
  }
}
