.backgroundImage {
  background-image: url(/public/backgrounds/ourCommunityPage.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.sectionsGap {
  gap: 100px;
  display: flex;
  flex-direction: column;
}

.welcomeContainer {
  width: 100%;
  height: auto;
  height: auto;
  position: relative;
}

.imageContainer {
  width: 100%;
  height: auto;
}

.homeWelcomeImage {
  width: inherit;
  object-fit: contain;
  width: -webkit-fill-available;
  height: 75vh;
}

.welcomeContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.lintrButtonContant {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 40px;
}

@media (max-width: 1250px) {
  .homeWelcomeImage {
    width: inherit;
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  .sectionsGap {
    gap: 200px;
  }
}

@media (max-width: 768px) {
  .leftSlot {
    padding-top: 100px;
  }
  .sectionsGap {
    gap: 150px;
  }
}

@media (max-width: 550px) {
  .sectionsGap {
    gap: 100px !important;
    margin-bottom: 50px;
  }
  .welcomeContent > h1 {
    max-width: 200px !important;
  }
}
