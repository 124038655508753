.footerContainer {
  height: 309px;
  width: 100%;
  background: var(--color-black);
  display: flex;
  flex-direction: column;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.footerContent {
  flex: auto;
}

.logoContainer {
  margin-right: 71px;
}

.footerContentCenter {
  padding-top: 58px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.footerTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
}

.footerAboutContainer {
  height: 136px;
  width: 604px;
  color: var(--color-white);
  padding-right: 46px;
}

.aboutText {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.01em;
  text-align: left;
}

.footerCopyrightContainer {
  display: flex;
  height: 62px;
  width: 100%;
  border-top: 1px solid #4f4f4f;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
}

@media (max-width: 1250px) {
  .footerContainer {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .logoContainer > svg {
    transform: scale(1.3);
    margin-left: 30px;
  }
  .footerContent {
    flex: auto;
    height: auto;
    padding: 0 40px;
  }
  .aboutText {
    min-width: 246px;
    margin-top: 10px;
    max-width: 700px;
  }

  .footerContentCenter {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: 40px;
  }
  .footerAboutContainer {
    width: auto;
    height: auto;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .footerContainer {
    min-height: 100vh;
    transition: all 0.3s ease-in-out;
  }
  .footerContent {
    padding: 0 40px;
  }
  .footerTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
  }
  .footerAboutTitle {
    font-size: 16px;
    line-height: 28px;
  }

  .aboutText {
    min-width: 246px;
    margin-top: 10px;
    max-width: 500px;
  }
  .logoContainer > svg {
    transform: scale(1);
    margin-left: 0;
  }
  .footerContentCenter {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: 20px;
    transition: all 0.3s ease-in-out;
  }
  .footerAboutContainer {
    width: auto;
    height: auto;
    margin-top: 30px;
    padding-right: 0px;
  }
}

@media (max-width: 550px) {
  .aboutText {
    line-height: 20px;
    font-size: 12px;
  }
  .footerContainer {
    min-height: 100vh;
  }

  .footerAboutContainer {
    padding-top: 20px;
  }

  .footerAboutTitle {
    margin-bottom: 15px;
  }
}
