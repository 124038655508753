@import './assets/font/Gilroy/Gilroy-ExtraBold/style.css';
@import './assets/font/Gilroy/Gilroy-Bold/style.css';
@import './assets/font/Gilroy/Gilroy-Medium/style.css';
@import './assets/font/Gilroy/Gilroy-Light/style.css';

:root {
  --color-primary: #f5a800;
  --color-white: #fafafa;
  --color-black: #262626;
  --color-blue: #00263a;
  --color-orange: #f5a800;
}

html,
body {
  font-family: 'Gilroy';
  font-weight: 500;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  background: var(--color-black);
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-family: Gilroy;
  font-size: 110px;
  line-height: 92px;
  letter-spacing: -0.055em;
  text-align: left;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

button {
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 1250px) {
  h1 {
    font-size: 75px;
    line-height: 80px !important;
  }
}

@media (max-width: 768px) {
  h1 {
    font-family: Gilroy;
    font-size: 55px;
    line-height: 50px !important;
    letter-spacing: -0.055em;
    text-align: left;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }

  h3 {
    font-family: Gilroy;
    font-size: 40px;
    line-height: 30px;
    letter-spacing: -0.055em;
    text-align: left;
    font-weight: 500;
  }
}

@media (max-width: 550px) {
  h1 {
    font-family: Gilroy;
    font-size: 48px !important;
    line-height: 45px !important;
    letter-spacing: -0.055em !important;
    text-align: left;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }

  h3 {
  }
}
