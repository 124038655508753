.container {
  height: 900px;

  position: relative;
}

.nextButton {
  display: flex;
  color: red;
  background: red;
  height: 50px;
  width: 20px;
  position: absolute;
  right: 50px;
  top: 20px;
}

/* div
  > swiper-pagination
  swiper-pagination-progressbar
  swiper-pagination-horizontal {
  position: absolute;
  top: 100%;
  place-items: bassline;
} */

.slideContent {
  text-align: center;
  font-size: 18px;
  height: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow-x: visible;
}

.imageWrapper > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 26px;
}

.imageWrapper {
  width: 393px;
  height: 393px;
}

.container > .swiperPagination {
  position: absolute;
  top: 95%;
  width: 55%;
  left: 23%;
}

.container > .swiperPagination > span {
  background: #383838 !important;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.wrapper > div > div {
  position: absolute;
  right: 210px;
}

@media (max-width: 1250px) {
  .container {
    height: 800px;
  }
  .wrapper > div > div {
    right: 118px;
  }
}

@media (max-width: 768px) {
  .imageWrapper {
    width: 250px;
    height: 250px;
  }

  .itemContainer {
    width: 250px;
    height: 250px;
  }

  .container > .swiperPagination {
    top: 92%;
    position: absolute;
  }
  .wrapper > div > div {
    right: -35px;
  }
}

@media (max-width: 550px) {
  .imageWrapper {
    width: 150px;
    height: 150px;
  }

  .itemContainer {
    width: 150px;
    height: 150px;
  }
  .container > .swiperPagination {
    top: 88%;
    position: absolute;
  }
  .wrapper > div > div {
    position: relative !important;
    right: 35px;
  }
}
