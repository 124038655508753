.image {
  height: 1111px;
  display: block;
  height: auto;
  object-fit: cover;
  width: 100%;
  min-width: 764px;
}

.aboutPageBackground {
  background-image: url(/public/backgrounds/aboutUsPage.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.welcomeContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.imageContainer {
  width: 100%;
  height: auto;
}

.homeWelcomeImage {
  width: 100%;
  object-fit: contain;
  min-height: auto;
}

.line {
  border: none;
  height: 1px;
  background-color: white;
  background: white;
  width: 100%;
  content: ' ';
  margin: 40px 0;
}

.welcomeContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.welcomeContent > h3 {
  color: white;
  max-width: 253px;
  text-align: center;
}

.aboutMainSection {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.rightSlot,
.leftSlot {
  max-width: 45%;
  min-width: 400px;
}
.slotHeader {
  height: 290px;
}

.slotBody {
  color: var(--color-white);
}

.slotBody > p {
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.aboutMainSection > article:nth-child(5) > .slotHeader {
  display: flex;
  justify-content: flex-end;
}

.articleTitle {
  max-width: 100%;
  background: linear-gradient(
    100.46deg,
    #dbf2ff 6.36%,
    #eeffe0 51.76%,
    #313131 92.2%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Especifica la cantidad de líneas que deseas mostrar */
  -webkit-box-orient: horizontal;
  overflow: hidden;
}

.sectionGap {
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.line {
  border: none;
  height: 1px;
  background-color: white;
  background: white;
  width: 100%;
  content: ' ';
}

.membersSectionContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.membersSectionTitleContainer {
  max-width: 620px;
}

.membersSectionTitleContainer > h1 {
  font-size: 90px;
  line-height: 79px;
  letter-spacing: -0.055em;
  text-align: left;
}
.membersSectionTextContainer {
  max-width: 45%;
  display: flex;
  flex-direction: column-reverse;
}
.membersSectionText {
  max-width: 485px;
  color: var(--color-white);
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width: 1500px) {
  .articleTitle {
    font-size: 90px;
    line-height: 90px;
  }
}

@media (max-width: 1250px) {
  .aboutMainSection {
    gap: 70px;
  }

  .welcomeContent > h1 {
    font-size: 40px !important;
  }

  .homeWelcomeImage {
    width: inherit;
    object-fit: contain;
    min-height: auto;
  }

  .rightSlot,
  .leftSlot {
    max-width: 100%;
    min-width: 100%;
    min-height: auto;
  }

  .slotHeader {
    padding-bottom: 50px;
    height: auto;
  }

  .articleTitle {
    font-size: 70px;
    line-height: 90px;
    max-width: 65%;
  }
  .membersSectionContainer {
    flex-direction: column;
  }

  .membersSectionTextContainer {
    max-width: 80%;
  }
  .membersSectionText {
    max-width: 700px;
  }
  .membersSectionTitleContainer > h1 {
    font-size: 75px;
    line-height: 80px;
  }
}

@media (max-width: 768px) {
  .welcomeContent > h1 {
    font-size: 30px !important;
  }

  .articleTitle {
    font-size: 45px !important;

    line-height: 45px !important;
  }
  .membersSectionTitleContainer > h1 {
    font-size: 55px;
    line-height: 50px;
  }
}

@media (max-width: 550px) {
  .membersSectionTitleContainer > h1 {
    font-size: 48px;
    line-height: 45px;
  }
  .articleTitle {
    max-width: 100%;
  }
}
