.b2bServicesPageBackground {
  background-image: url(/public/backgrounds/b2bServicesPage.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.sectionsGap {
  gap: 150px;
  display: flex;
  flex-direction: column;
}

.sectionContainer {
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
}

.rightSlot {
  max-width: 50%;
  width: auto;
  display: flex;
  height: 100%;
  flex: auto;
}

.rightSlotContainer {
  height: 100%;
  position: relative;
  width: -webkit-fill-available;
}

.rightSlotImage {
  position: absolute;
  top: 0%;
  width: 80%;
  right: 0;
}

.leftSlot {
  width: auto;
  max-width: 50%;
  flex: auto;
  display: flex;
  height: 100%;
}

.leftSlotContent {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftSlotHeader {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.leftSlotHeader > h4 {
  color: var(--color-white);
  font-size: 104px;
  line-height: 90px;
  letter-spacing: 0.005em;
  font-weight: 500;
  height: auto;
}
.leftSlotBody {
  width: 100%;
  height: auto;
  flex: auto;
  display: flex;
  color: var(--color-white);
  text-align: start;
  background: transparent;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.01em;
  padding-top: 70px;
}
.leftSlotBody > p {
  max-width: 500px;
}
.leftSlotFooter {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
}
.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  flex: row;
  gap: 14px;
  width: 100%;
}

.RightFigureButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

@media (max-width: 1250px) {
  .sectionContainer {
    flex-direction: column;
    height: auto;
    gap: 100px;
  }

  .sectionsGap {
    gap: 100px;
  }

  .rightSlot {
    max-width: 100%;
  }
  .leftSlot {
    max-width: 100%;
  }
  .rightSlotImage {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: relative;
  }

  .leftSlotContent {
    height: min-content;
  }

  .leftSlotHeader > h4 {
    font-size: 27px;
  }

  .leftSlotFooter {
    padding-top: 50px;
  }
}

@media (max-width: 768px) {
  .rightSlot {
    padding-top: 100px;
  }

  .leftSlotHeader > h4 {
    font-size: 20px;
  }
  .leftSlotBody > p {
    font-size: 14px;
    line-height: 23px;
  }
  .rightSlotImage {
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 550px) {
  .leftSlotHeader > h4 {
    font-size: 18px;
  }

  .rightSlot {
    padding-top: 0px;
  }
  .sectionsGap {
  }
}
