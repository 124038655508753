.footerMapContainer {
  color: var(--color-white);
}

.footerMapNavigation {
  display: flex;
  flex-direction: row;
  list-style: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.01em;
  margin-top: 10px;
}

.footerMapNavigation > div {
  margin-right: 10px;
  width: 148px;
}

@media (max-width: 1250px) {
  .footerMapContainer {
    width: auto;
    margin-top: 40px;
  }
  .footerMapNavigation {
    width: 300px;
    gap: 80px;
  }
  .footerTitle {
    font-size: 20px;
    line-height: 32px;
  }
  .footerMapNavigation > div > li {
    margin: 10px 0;
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .footerMapContainer {
    width: 300px;
    margin-top: 40px;
  }
  .footerMapNavigation {
    width: 300px;
    gap: 50px;
  }
  .footerMapNavigation > div > li {
    margin: 10px 0;
    font-size: 14px;
    line-height: 26px;
  }
  .footerTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.01em;
  }
}
/*
@media (max-width: 550px) {
  .footerMapContainer {
    width: auto;
    margin-top: 40px;
  }

  .footerMapNavigation {
    margin-top: 10px;
    width: 300px;
    gap: 30px;
  }
  .footerMapNavigation > div > li {
    margin: 10px 0;
  }

  .footerTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.01em;
  }
} */
