.contentWrapper {
  padding: 90px 220px 50px 220px;
  background: transparent;
  transition: all 0.3s ease-in-out;
  display: list-item;
  height: auto;
  min-height: max-content;
}

@media (max-width: 1750px) {
  .contentWrapper {
    padding: 80px 110px 50px 110px;
  }
}

@media (max-width: 1500px) {
  .contentWrapper {
    padding: 70px 110px 50px 110px;
  }
}

@media (max-width: 1250px) {
  .contentWrapper {
    padding: 50px 110px 50px 110px;
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    padding: 50px 60px;
  }
}

@media (max-width: 550px) {
  .contentWrapper {
    padding: 50px 20px;
  }
}
