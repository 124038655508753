.container {
  box-sizing: border-box;
  width: 379px;
  height: 580px;
  background: #222222;
  box-shadow: 0px 0px 37px rgba(238, 238, 238, 0.03);
  border-radius: 29px;
  padding: 64px 42px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 20px
    linear-gradient(180deg, #171717 0%, #035d71 100%);
  transition: all ease-in-out 0.3s;
  justify-content: space-between;
}

.subtitle {
  font-size: 18px;
  line-height: 21px;
  color: #f5a800;
  text-align: center;
}

.text {
  padding-top: 40px;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;
  flex: auto;
  color: #ffffff;
}

.container > button {
  height: 45px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 768px) {
  .container {
    width: 280px;
    height: 440px;
    background: #222222;
    padding: 30px 20px 40px 20px;
  }
  .text {
    padding-top: 20px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }
}

@media (max-width: 550px) {
  .header > h1 {
    font-size: 35px !important;
  }

  .text {
    font-size: 16px;
    line-height: 22px;
  }
}
