/* .text {
  font-size: 55px;
  line-height: 66px;
  letter-spacing: -0.01em;
  max-width: 786px;
}

@media (max-width: 1250px) {
  .text {
    font-size: 45px;
    line-height: 56px;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 35px;
    line-height: 46px;
  }
}

@media (max-width: 550px) {
  .text {
    font-size: 22px;
    line-height: 36px;
  }

} */

.text {
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0em;
  color: var(--color-white);
}

@media (max-width: 768px) {
  .text {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--color-white);
  }
}
