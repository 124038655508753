.cardContainer {
  height: 357px;
  width: 610px;
  border-radius: 40px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardHeader {
  height: 98px;
  width: 90%;
  margin-top: 15px;
  position: relative;
  border-radius: 69px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardHeader > img {
  max-height: 70px;
  max-width: 215px;
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 35px 0 35px;

  letter-spacing: 0em;
  text-align: left;
}

.cardTitle > strong {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
}

.cardDescription {
  font-size: 16px;
  line-height: 29px;
}

@media (max-width: 1250px) {
  .cardDescription {
    font-size: 16px;
    line-height: 29px;
  }
}

@media (max-width: 768px) {
  .cardContainer {
    width: 420px;
    height: 450px;
  }
  .cardHeader {
    height: 68px;
  }
  .cardHeader > img {
    max-height: 40px;
    max-width: 130px;
  }

  .cardDescription {
    font-size: 16px;
    line-height: 29px;
  }
}

@media (max-width: 550px) {
  .cardContainer {
    width: 300px;
  }

  .cardDescription {
    font-size: 14px;
    line-height: 25px;
  }
}
