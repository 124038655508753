.container {
  height: auto;
  width: 100%;
  border-radius: 10px;
  gap: 100px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 100px;
}

.sectionPaddingWrapper {
  padding: 0 220px;
  background: transparent;
  transition: all 0.3s ease-in-out;
  display: list-item;
  height: auto;
  min-height: max-content;
}

.headerContainer {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 75px;
  margin-bottom: 100px;
}

.title {
  color: var(--color-orange);
  font-size: 32px;
  width: max-content;
  line-height: 36px;
  letter-spacing: 0.005em;
  font-weight: 500;
  white-space: nowrap;
}

.line {
  height: 1px;
  content: '';
  width: 100%;
  background: var(--color-white);
  border-radius: nullpx;
}

.textContainer {
  display: flex;
  text-align: center;
  width: fit-content;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 80px;
  line-height: 96px;
  letter-spacing: -0.01em;
  text-align: center;

  max-width: 90%;
  color: var(--color-white);
}

.dividerButtonContainer {
  width: auto;
}

.sliderContainer {
  width: 100%;
}

.dividerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
  margin: 50px 0;
}

.dividerButtonContainer > button > {
  font-size: 15px;
}

.container > .swiperPagination > span {
  background: #e5a000 !important;
}

@media (max-width: 1750px) {
  .sectionPaddingWrapper {
    padding: 0 110px;
  }
}

@media (max-width: 1250px) {
  .sectionPaddingWrapper {
    padding: 0 110px;
  }
  .container {
    height: auto;
  }

  .headerContainer {
    gap: 50px;
    margin-bottom: 50px;
  }

  .title {
    font-size: 27px;
  }

  .text {
    font-size: 45px;
    line-height: 56px;
  }

  .bottomContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .sectionPaddingWrapper {
    padding: 0 60px;
  }
  .container {
    gap: 50px;
  }
  .headerContainer {
    gap: 25px;
    margin-bottom: 30px;
  }

  .title {
    font-size: 22px;
  }

  .text {
    font-size: 35px;
    line-height: 46px;
  }
}

@media (max-width: 550px) {
  .headerContainer {
    margin-bottom: 15px;
  }
  .sectionPaddingWrapper {
    padding: 0 20px;
  }
  .title {
    font-size: 16px;
  }

  .text {
    text-align: center;
    font-size: 20px;
    line-height: 25px;
  }

  .dividerContainer {
    gap: 25px;
  }
}
